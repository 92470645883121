.modal {
  @include font1;
  h3 {
    letter-spacing: 2px;
  }
  p {
    line-height: 22px;
  }
}

section.neighborhood-container {
  @include font1;
  overflow: hidden;
  position: relative;
  h1{
    position: absolute;
    top: 0;
    left: 60px;
    @include iphone {
      left: 15px;
    };
  }
  .neighborhood-intro-box { //add transparent white box under neighborhood intro
    background: rgba(255,255,255,0.875);
    position: absolute;
    left: 15px;
    top: 15px;
    width: 37%;
    height: 220px;
    @include ipad-landscape{
      height: 256px;
    }
    @include ipad-portrait {
      width: 85%;
    }
    @include iphone {
      width: 91%;
      height: 240px;
    }
    @include iphone6-plus-landscape {
      height: 168px;
    }
    @include iphone5-landscape {
      height: 180px;
    }
    @include iphone6-plus-portrait{
      height: 220px;
    }
    @include iphone6-portrait{
      height: 240px;
    }
    @include iphone5-portrait{
      height: 260px;
    }
  }
  .neighborhood-intro-box:before { //controls height of transparent white box
    content: "";
    display: block;
    padding-top: 30%
  }
  .icon-neighborhood {
    left: 15px;
  }
  .about-hood {
    @include font2
    position: absolute;
    top: 50px;
    left: 60px;
    // width: 40%;
    width: 82%; //copy fills white box area
    @include iphone {
      left: 15px;
      width: 90%;
    };
  }
  .neighbor-corner {
    @include hexa-trans;
    background-size: cover;
    width: 98px;
    height: 112px;
    position: absolute;
    bottom: 15%;
    left: 37.5%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding-top: 30px;
    cursor: pointer;
    line-height: 18px;
    letter-spacing: 1px;
    @include ipad-landscape {
      bottom: 28%;
      left: 40.5%;
    }
    @include ipad-portrait {
      bottom: 32%;
      left: 10.5%;
    }
    @include iphone {
      bottom: 30px;
      left: 42px;
    }
    @include iphone6-plus-landscape {
      bottom: 80px;
      left: 272px;
    }
    @include iphone6-landscape {
      left: 222px;
    }
    @include iphone5-landscape {
      left: 182px;
    }
    @include iphone6-plus-portrait {
      bottom: 221px;
      left: 122px;
    }
    @include iphone6-portrait {
      bottom: 183px;
      left: 40px;
    }
    @include iphone5-portrait {
      bottom: 160px;
      left: -3px;
    }
    @include hexa-trans;
    background-size: cover;
    width: 98px;
    height: 112px;
    position: absolute;
    bottom: 33%;
    left: 42.5%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding-top: 30px;
    cursor: pointer;
    line-height: 18px;
    letter-spacing: 1px;
    p {
      font-size: 10px;
    }
    &:hover {
      @include desktop {
        @include hexa-trans-hover;
      };
    }
  }
  img {
    padding: 0;
    width: 100%;
    @include ipad-portrait {
      height: calc(100vh - 60px);
      width: auto;
      position: relative;
      left: -600px;
    };
    @include ipad-landscape {
      width: 100%;
      height: auto;
      left: 0;
    };
    @include iphone {
      height: 100vh;
      width: auto;
      position: relative;
      left: -420px;
    };
    @include iphone-landscape {
      width: 100%;
      height: auto;
      left: 0;
    };
  }
}
