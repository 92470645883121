$trans: background .8s;

@mixin plan-btn {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE3MXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAxNzEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4yICgyODI3NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5IDExPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ijc2OC0tLWRlc2lnbi0xLS0taVBhZC12ZXJ0aWNhbCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM4NC4wMDAwMDAsIC0xMjM3LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1IiBmaWxsPSIjOTc5Nzk3Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3OS4wMDAwMDAsIDEwMjIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iYnV0dG9uLW5laWdoYm9yaG9vZC1hZXJpYWwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU0MC4wMDAwMDAsIDIxOC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iaGV4YWdvbi10aXRsZS1jb3B5LTExIiBwb2ludHM9IjM1LjM4MzIyMiAyLjEzMTYyODIxZS0xNCAyNiAxNy41IDM1LjM4MzIyMiAzNSAxODAuOTU3MDMxIDM1IDE5MC4zNDAyNTMgMTcuNSAxODAuOTU3MDMxIDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin plan-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE3MXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAxNzEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5IDU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMzc1LS0tZGVzaWduLTEtLS1pUGhvbmUtNi12ZXJ0aWNhbCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE1LjAwMDAwMCwgLTk3OC4wMDAwMDApIiBzdHJva2Utd2lkdGg9IjUiIHN0cm9rZT0iI0RFNjYyNSIgZmlsbD0iI0RFNjYyNSI+CiAgICAgICAgICAgIDxnIGlkPSJzaXRlLXBsYW4iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMzUuMDAwMDAwLCA2NjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iYnV0dG9uLWRpZ2l0YWwtYnJvY2h1cmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIyNy4wMDAwMDAsIDMxNC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iaGV4YWdvbi10aXRsZS1jb3B5LTUiIHBvaW50cz0iMzUuMzgzMjIyIDIuMTMxNjI4MjFlLTE0IDI2IDE3LjUgMzUuMzgzMjIyIDM1IDE4MC45NTcwMzEgMzUgMTkwLjM0MDI1MyAxNy41IDE4MC45NTcwMzEgMCI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin hexa-btn {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIyMXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAyMjEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4xICgyODIxNSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5IDU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8cG9seWdvbiBpZD0icGF0aC0xIiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0iIzYzNjA2MCIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3MS4wMDAwMDAsIC05OTYuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJwbGFuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMzLjAwMDAwMCwgOTMwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImJ1dHRvbi1ibGRnLWEtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA3LjAwMDAwMCwgNjkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImhleGFnb24tdGl0bGUtY29weS01Ij4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsLW9wYWNpdHk9IjAuMzciIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBtdWx0aXBseTsiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgICAgICAgICAgICAgICAgICA8dXNlIHN0cm9rZT0iI0RFNjYyNSIgc3Ryb2tlLXdpZHRoPSI1IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin hexa-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIyMXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAyMjEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWRpZ2l0YWwtYnJvY2h1cmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3MS4wMDAwMDAsIC0xMDAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tZGlnaXRhbC1icm9jaHVyZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTUwLjAwMDAwMCwgNzQuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS01IiBmaWxsPSIjOTc5Nzk3IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS02IiBmaWxsPSIjREU2NjI1IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin caption {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjQyNHB4IiBoZWlnaHQ9IjkycHgiIHZpZXdCb3g9IjAgMCA0MjQgOTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4zICgyOTgwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIyMy4wNTU5MTcgNzU3IDAgODAwIDIzLjA1NTkxNyA4NDMgNTA1LjI1IDg0MyA1MjguMzA1OTE3IDgwMCA1MDUuMjUgNzU3Ij48L3BvbHlnb24+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIC0yNjIxLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0idGhlLWNvbmNlcHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDguMDAwMDAwLCAxODY3LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InJlbmRlcmluZy0xLWZyb250Ij4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iaGV4YWdvbi10aXRsZS1jb3B5Ij4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsLW9wYWNpdHk9IjAuNiIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6IG11bHRpcGx5OyIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICAgICAgICAgIDx1c2Ugc3Ryb2tlPSIjREU2NjI1IiBzdHJva2Utd2lkdGg9IjUiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin mobile-caption {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjM1M3B4IiBoZWlnaHQ9IjkycHgiIHZpZXdCb3g9IjAgMCAzNTMgOTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4yICgyODI3NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIyMy4wNTU5MTcgMS44MDAwNDkyOGUtMTQgMCA0MyAyMy4wNTU5MTcgODYgNTA1LjI1IDg2IDUyOC4zMDU5MTcgNDMgNTA1LjI1IDAiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSI3NjgtLS1kZXNpZ24tMS0tLWlQYWQtdmVydGljYWwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMTY0MC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3OS4wMDAwMDAsIDEwMjIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0ibGlzdC1mZWF0dXJlcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDYyMS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iaW5zdHJ1Y3Rpb25zIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImhleGFnb24tdGl0bGUtY29weSI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8dXNlIGZpbGwtb3BhY2l0eT0iMC4zNyIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6IG11bHRpcGx5OyIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8dXNlIHN0cm9rZT0iI0RFNjYyNSIgc3Ryb2tlLXdpZHRoPSI1IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin hexa-plan {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE3MXB4IiBoZWlnaHQ9IjE5OHB4IiB2aWV3Qm94PSIwIDAgMTcxIDE5OCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy43LjIgKDI4Mjc2KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5idXR0b24tdGhlLWNvcm5lcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxNDQwLS0tZGVzaWduLTEtLS1pbnRlcmlvci1oZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIwNi4wMDAwMDAsIC0xNTkyLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tdGhlLWNvcm5lciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM4NS4wMDAwMDAsIDY2NS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iYW1lbml0aWVzLWxvY2FsLWxpdmluZy1oZXhhZ29uIiBmaWxsPSIjMjY5RDgzIiBwb2ludHM9IjgyLjgxNDU4MjYgMC4zNzUgMTY1LjQ0MDYxMiA0OC4wNzkxNjA1IDE2NS40NDA2MTIgMTQzLjQ4NzQ4MSA4Mi44MTQ1ODI2IDE5MS4xOTE2NDIgMC4xODg1NTI4NDcgMTQzLjQ4NzQ4MSAwLjE4ODU1Mjg0NyA0OC4wNzkxNjA1Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhvdmVyIiBmaWxsPSIjOTc5Nzk3IiBwb2ludHM9IjgyLjgxNDU4MjYgMC4zNzUgMTY1LjQ0MDYxMiA0OC4wNzkxNjA1IDE2NS40NDA2MTIgMTQzLjQ4NzQ4MSA4Mi44MTQ1ODI2IDE5MS4xOTE2NDIgMC4xODg1NTI4NDcgMTQzLjQ4NzQ4MSAwLjE4ODU1Mjg0NyA0OC4wNzkxNjA1Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin hexa-plan-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE3MXB4IiBoZWlnaHQ9IjE5OHB4IiB2aWV3Qm94PSIwIDAgMTcxIDE5OCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy44LjIgKDI5NzUzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5idXR0b24tdGhlLWNvcm5lcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxNDQwLS0tZGVzaWduLTEtLS1pbnRlcmlvci1oZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIwNi4wMDAwMDAsIC0xNTkyLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tdGhlLWNvcm5lciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM4NS4wMDAwMDAsIDY2NS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iYW1lbml0aWVzLWxvY2FsLWxpdmluZy1oZXhhZ29uIiBmaWxsPSIjMjY5RDgzIiBwb2ludHM9IjgyLjgxNDU4MjYgMC4zNzUgMTY1LjQ0MDYxMiA0OC4wNzkxNjA1IDE2NS40NDA2MTIgMTQzLjQ4NzQ4MSA4Mi44MTQ1ODI2IDE5MS4xOTE2NDIgMC4xODg1NTI4NDcgMTQzLjQ4NzQ4MSAwLjE4ODU1Mjg0NyA0OC4wNzkxNjA1Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhvdmVyIiBmaWxsPSIjREU2NjI1IiBwb2ludHM9IjgyLjgxNDU4MjYgMC4zNzUgMTY1LjQ0MDYxMiA0OC4wNzkxNjA1IDE2NS40NDA2MTIgMTQzLjQ4NzQ4MSA4Mi44MTQ1ODI2IDE5MS4xOTE2NDIgMC4xODg1NTI4NDcgMTQzLjQ4NzQ4MSAwLjE4ODU1Mjg0NyA0OC4wNzkxNjA1Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin hexa-solid {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjkwcHgiIGhlaWdodD0iMTA0cHgiIHZpZXdCb3g9IjAgMCA5MCAxMDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4yICgyODI3NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+UG9seWdvbiAzNSBDb3B5PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNjYuMDAwMDAwLCAtNDQ4NC4wMDAwMDApIiBzdHJva2Utd2lkdGg9IjUiIHN0cm9rZT0iI0RFNjYyNSIgZmlsbD0iIzk3OTc5NyI+CiAgICAgICAgICAgIDxnIGlkPSJhbWVuaXRpZXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03Ny4wMDAwMDAsIDM3MjAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUG9seWdvbi0zNS1Db3B5IiBwb2ludHM9IjQ4OCA3NjcuNTM1ODk4IDUzMCA3OTEuNzg0NjEgNTMwIDg0MC4yODIwMzIgNDg4IDg2NC41MzA3NDQgNDQ2IDg0MC4yODIwMzIgNDQ2IDc5MS43ODQ2MSI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin hexa-solid-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjkwcHgiIGhlaWdodD0iMTA0cHgiIHZpZXdCb3g9IjAgMCA5MCAxMDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+UG9seWdvbiAzNSBDb3B5IDM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM2Ni4wMDAwMDAsIC00NDg0LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1IiBmaWxsPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9ImFtZW5pdGllcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3LjAwMDAwMCwgMzcyMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQb2x5Z29uLTM1LUNvcHktMyIgcG9pbnRzPSI0ODggNzY3LjUzNTg5OCA1MzAgNzkxLjc4NDYxIDUzMCA4NDAuMjgyMDMyIDQ4OCA4NjQuNTMwNzQ0IDQ0NiA4NDAuMjgyMDMyIDQ0NiA3OTEuNzg0NjEiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin hexa-trans {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjczcHgiIGhlaWdodD0iODRweCIgdmlld0JveD0iMCAwIDczIDg0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjcuMiAoMjgyNzYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPmJ1dHRvbi1ob21lcGFnZS1jbGljay1zY3JvbGw8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8cG9seWdvbiBpZD0icGF0aC0xIiBwb2ludHM9IjQxLjM1MTUyMzggNiAyMiAzOS41MTgxMjc2IDQxLjM1MTUyMzggNzMuMDM2MjU1MSA4MC4wNTQ1NzEzIDczLjAzNjI1NTEgOTkuNDA0NjY5OSAzOS41MTk1NTI4IDk5LjQwNDY2OTkgMzkuNTE1Mjc3MSA4MC4wNTQ1NzEzIDYiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxNDQwLS0tZGVzaWduLTEtLS1pbnRlcmlvci1oZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjYzLjAwMDAwMCwgLTc3NS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9ImhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI4LjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tc2Nyb2xsLXRvLW5leHQtc2VjdGlvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzY3LjAwMDAwMCwgNzc3LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24taG9tZXBhZ2UtY2xpY2stc2Nyb2xsIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MC43MDIzMzUsIDM5LjUxODEyOCkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtNjAuNzAyMzM1LCAtMzkuNTE4MTI4KSAiPgogICAgICAgICAgICAgICAgICAgICAgICA8dXNlIGZpbGwtb3BhY2l0eT0iMC41IiBmaWxsPSIjNEE0QTRBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbXVsdGlwbHk7IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBzdHJva2U9IiNERTY2MjUiIHN0cm9rZS13aWR0aD0iNSIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin hexa-trans-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9Ijk4cHgiIGhlaWdodD0iMTEycHgiIHZpZXdCb3g9IjAgMCA5OCAxMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+UG9seWdvbiAzNSBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUzOC4wMDAwMDAsIC0zNDI5LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1IiBmaWxsPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9Im5laWdoYm9yaG9vZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDI3OTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUG9seWdvbi0zNS1Db3B5LTIiIHBvaW50cz0iNTg3IDY0MiA2MzIuOTI4MjAzIDY2OC41MTY2NiA2MzIuOTI4MjAzIDcyMS41NDk5ODEgNTg3IDc0OC4wNjY2NDIgNTQxLjA3MTc5NyA3MjEuNTQ5OTgxIDU0MS4wNzE3OTcgNjY4LjUxNjY2Ij48L3BvbHlnb24+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin mobile-broker {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE0M3B4IiBoZWlnaHQ9IjUwcHgiIHZpZXdCb3g9IjAgMCAxNDMgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4yICgyODI3NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWhvbWVwYWdlLWJyb2tlci10b29sYm94PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMS42NDY4MjMzIDAuNDk0MzQyNDY4IDAgMjIuMjQ3MTcxMiAxMS42NDY4MjMzIDQ0IDI1NS4yMjk4MTcgNDQgMjY2Ljg3NjY0IDIyLjI0NzE3MTIgMjU1LjIyOTgxNyAwLjQ5NDM0MjQ2OCI+PC9wb2x5Z29uPgogICAgPC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjM3NS0tLWRlc2lnbi0xLS0taVBob25lLTYtdmVydGljYWwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNTEwLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iaG9tZXBhZ2UiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MzIuMDAwMDAwLCAtMTE2LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImJ1dHRvbi1icm9rZXItbGliZXJhcnkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwNS4wMDAwMDAsIDYyOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iYnV0dG9uLWhvbWVwYWdlLWJyb2tlci10b29sYm94Ij4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsLW9wYWNpdHk9IjAuMzciIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBtdWx0aXBseTsiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgICAgICAgICAgICAgICAgICA8dXNlIHN0cm9rZT0iI0RFNjYyNSIgc3Ryb2tlLXdpZHRoPSI1IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  left: -25px;
}

@mixin broker-tool {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIwM3B4IiBoZWlnaHQ9IjU5cHgiIHZpZXdCb3g9IjAgMCAyMDMgNTkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4yICgyODI3NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWhvbWVwYWdlLWJyb2tlci10b29sYm94PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxNC4zMTQzMjE1IDAuNjA2NjkzMDI5IDAgMjcuMzAzMzQ2NSAxNC4zMTQzMjE1IDU0IDMxMy42ODU2NzggNTQgMzI4IDI3LjMwMzM0NjUgMzEzLjY4NTY3OCAwLjYwNjY5MzAyOSI+PC9wb2x5Z29uPgogICAgPC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNzQ1LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iaG9tZXBhZ2UiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjguMDAwMDAwLCAwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImJ1dHRvbi1icm9rZXItbGliZXJhcnkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA3NDcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImJ1dHRvbi1ob21lcGFnZS1icm9rZXItdG9vbGJveCI+CiAgICAgICAgICAgICAgICAgICAgICAgIDx1c2UgZmlsbC1vcGFjaXR5PSIwLjM3IiBmaWxsPSIjNEQ0RDREIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTogbXVsdGlwbHk7IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBzdHJva2U9IiNERTY2MjUiIHN0cm9rZS13aWR0aD0iNSIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin broker-tool-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIwM3B4IiBoZWlnaHQ9IjU5cHgiIHZpZXdCb3g9IjAgMCAyMDMgNTkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWhvbWVwYWdlLWJyb2tlci10b29sYm94PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNzQ1LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1IiBmaWxsPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9ImhvbWVwYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI4LjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tYnJva2VyLWxpYmVyYXJ5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNzQ3LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJidXR0b24taG9tZXBhZ2UtYnJva2VyLXRvb2xib3giIHBvaW50cz0iMTQuMzE0MzIxNSAwLjYwNjY5MzAyOSAwIDI3LjMwMzM0NjUgMTQuMzE0MzIxNSA1NCAzMTMuNjg1Njc4IDU0IDMyOCAyNy4zMDMzNDY1IDMxMy42ODU2NzggMC42MDY2OTMwMjkiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin plan-label {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI4cHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDI4IDMyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjcuMiAoMjgyNzYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPlBvbHlnb24gMSBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5My4wMDAwMDAsIC0xNTU0LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1ODQuMDAwMDAwLCA1MzYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImxhYmVsLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg0LjAwMDAwMCwgOTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQb2x5Z29uLTEtQ29weS0yIiBwb2ludHM9IjE1IDAgMjcuMTI0MzU1NyA3IDI3LjEyNDM1NTcgMjEgMTUgMjggMi44NzU2NDQzNSAyMSAyLjg3NTY0NDM1IDciPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  transition: $trans;
}

@mixin plan-label-hover {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI4cHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDI4IDMyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjcuMiAoMjgyNzYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkdyb3VwIDM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5My4wMDAwMDAsIC0xNTU0LjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1ODQuMDAwMDAwLCA1MzYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImxhYmVsLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg0LjAwMDAwMCwgOTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyLjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQb2x5Z29uLTEtQ29weS0yIiBwb2ludHM9IjEzIDAgMjUuMTI0MzU1NyA3IDI1LjEyNDM1NTcgMjEgMTMgMjggMC44NzU2NDQzNDcgMjEgMC44NzU2NDQzNDcgNyI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBvbHlnb24tMS1Db3B5IiBmaWxsPSIjREU2NjI1IiBwb2ludHM9IjEzIDAgMjUuMTI0MzU1NyA3IDI1LjEyNDM1NTcgMjEgMTMgMjggMC44NzU2NDQzNDcgMjEgMC44NzU2NDQzNDcgNyI+PC9wb2x5Z29uPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}