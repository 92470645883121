// Modal position
.floor-plans {
  margin-top: 4vh;
}


// Modal contents
.floor {
  transition: background 1s;
  margin-top: 10px;
  height: 500px;
  @include iphone {
    height: 220px;
  };
}

.a-floor-1 {
  background: url('../images/building-a-flr-1.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.a-floor-2 {
  background: url('../images/building-a-flr-2.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.b-floor-1 {
  background: url('../images/building-b-flr-1.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
  width: 100%;
}

.b-floor-2 {
  background: url('../images/building-b-flr-2.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
  width: 100%;
}

.c-floor-1 {
  background: url('../images/building-c-flr-1.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
  width: 100%;
  @media (min-width: 1240px) {
    left: 110px;
  }
}

.c-floor-2 {
  background: url('../images/building-c-flr-2.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
  width: 100%;
  @media (min-width: 1240px) {
    left: 110px;
  }
}

.floor-label {
  @include font2;
  position: absolute;
  text-align: center;
  font-size: 24px;
  top: 92px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 60px;
  @media (max-width: 991px) {
    display: none;
  }
  .arrow-up {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
  	height: 0;
  	border-left: 25px solid transparent;
  	border-right: 25px solid transparent;
  	border-bottom: 15px solid #9B9B9B;
  }
}
.floor-label-b {
  @media (max-width: 991px) {
      display: none;
  }
  @include font2;
  position: absolute;
  text-align: center;
  font-size: 24px;
  bottom: 105px;
  right: -220px;
  transform: translateX(-50%);
  width: 400px;
  height: 60px;
  .arrow-down {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
  	height: 0;
  	border-left: 25px solid transparent;
  	border-right: 25px solid transparent;
  	border-top: 15px solid #9B9B9B;
    margin-bottom: 10px;
  }
}
.floor-label-c {
  @include font2;
  position: absolute;
  text-align: center;
  font-size: 24px;
  bottom: 105px;
  right: -220px;
  transform: translateX(-50%);
  width: 400px;
  height: 60px;
  .arrow-down {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
  	height: 0;
  	border-left: 25px solid transparent;
  	border-right: 25px solid transparent;
  	border-top: 15px solid #9B9B9B;
    margin-bottom: 10px;
  }
}

// Download Floor Plan btn
.download-fp {
  @include hexa-solid;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  color: #fff;
  padding: 12px 0 0 14px;
  font-size: 20px;
  &:hover {
    color: #fff;
    @include desktop {
      @include hexa-solid-hover;
    };
  }
}

.bottom-margin {
  margin-bottom: 15px;
}


.floor-btn {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIyMXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAyMjEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuNy4xICgyODIxNSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+aGV4YWdvbi10aXRsZSBjb3B5IDU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8cG9seWdvbiBpZD0icGF0aC0xIiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3MS4wMDAwMDAsIC05OTYuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJwbGFuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMzLjAwMDAwMCwgOTMwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImJ1dHRvbi1ibGRnLWEtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA3LjAwMDAwMCwgNjkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9ImhleGFnb24tdGl0bGUtY29weS01Ij4KICAgICAgICAgICAgICAgICAgICAgICAgPHVzZSBmaWxsLW9wYWNpdHk9IjAuMzciIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBtdWx0aXBseTsiIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPgogICAgICAgICAgICAgICAgICAgICAgICA8dXNlIHN0cm9rZT0iI0RFNjYyNSIgc3Ryb2tlLXdpZHRoPSI1IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 221px;
  text-align: center;
  height: 41px;
  color: white;
  padding-top: 1.5vh;
  margin-top: 10px;
  cursor: pointer;
  transition: background 1s;
  @include ipad-portrait {
    padding-top: 12px;
  };
  @include iphone {
    padding-top: 12px;
  };
  &:hover {
    @include desktop {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIyMXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAyMjEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWRpZ2l0YWwtYnJvY2h1cmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3MS4wMDAwMDAsIC0xMDAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tZGlnaXRhbC1icm9jaHVyZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTUwLjAwMDAwMCwgNzQuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS01IiBmaWxsPSIjOTc5Nzk3IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS02IiBmaWxsPSIjREU2NjI1IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
    };
  }
}

.active-floor {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIyMXB4IiBoZWlnaHQ9IjQxcHgiIHZpZXdCb3g9IjAgMCAyMjEgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDMuOC4yICgyOTc1MykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YnV0dG9uLWRpZ2l0YWwtYnJvY2h1cmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMTQ0MC0tLWRlc2lnbi0xLS0taW50ZXJpb3ItaGVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3MS4wMDAwMDAsIC0xMDAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlPSIjREU2NjI1Ij4KICAgICAgICAgICAgPGcgaWQ9InNpdGUtcGxhbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3Ni4wMDAwMDAsIDkzMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJidXR0b24tZGlnaXRhbC1icm9jaHVyZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTUwLjAwMDAwMCwgNzQuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS01IiBmaWxsPSIjOTc5Nzk3IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhleGFnb24tdGl0bGUtY29weS02IiBmaWxsPSIjREU2NjI1IiBwb2ludHM9IjkuMzgzMjIyMDMgLTIuMTA5NTkyNzVlLTE0IDAgMTcuNSA5LjM4MzIyMjAzIDM1IDIwNS42MjUgMzUgMjE1LjAwODIyMiAxNy41IDIwNS42MjUgLTIuODQyMTcwOTRlLTE0Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
  background-size: contain;
  background-repeat: no-repeat;
}

.border {
  padding: 20px;
  border: 5px solid #797979;
}

// Available floor option
.availability {
  position: relative;
  top: 2px;
  display: inline-block;
  min-width: 15px;
  min-height: 15px;
  border-radius: 20px;
  margin: 0 5px 0 20px;
}

.leased {
  background: $light-yellow;
  border: 1px solid $light-yellow;
}

.available {
  background: #fff;
  border: 1px solid #8e8e8e;
}

section.plan-container {
  @include iphone-landscape {
    padding-bottom: 0;
  };
  // Plan Header---------------------------------------------------------------
  .plan-header {
    color: #fff;
    background: $light-gray;
    h1, p {
      padding-left: 65px;
      @include iphone {
        padding-left: 10px;
      };
    }
    a {
      font-size: 16px;
      line-height: 18px;
    }
    .download-plans {
      padding: 5px;
    }
    .btns-container {
      max-width: 185px * 2;
      @include iphone {
        margin-left: 5px;
      };
      a {
        color: #fff;
      }
    }
    .download-btn {
      @include font2;
      @include plan-btn;
      display: inline-block;
      width: 165px;
      height: 40px;
      text-align: center;
      padding-top: 10px;
      margin: 0 10px 10px 0;
      @include iphone {
        margin: 0 0 8px 0;
      };
      &:hover {
        @include plan-hover;
      }
      @include iphone5-portrait {
        width: 140px;
        padding-top: 6px;
      };
    }
    padding-bottom: 20px;
  }
  // Map Section---------------------------------------------------------------
  .map-section {
    .plan-map-container {

        padding: 0;
        background: red;
        // Individual marker positions for plan-map

        .label-1 {
          top: 66.8%;
          left: 61%;
        }
        .label-2 {
          top: 58.8%;
          left: 44.3%;
        }
        .label-3 {
          top: 40.8%;
          left: 80.5%;
        }
        .label-4 {
          top: 18.5%;
          left: 50.7%;
        }
        .label-5 {
          top: 2%;
          left: 45.8%;
        }
        .label-6 {
          top: 41.8%;
          left: 58.25%;
        }
        // .label-7 {
        //   top: 2%;
        //   left: 45.8%;
        // }

        // General marker styling for plan-map
        .marker {
          cursor: pointer;
          @include font2;
          @include iphone {
            display: none;
          };
          position: absolute;
          z-index: 100;
          .marker-wrapper {
            position: relative;
            width: 140px;
            .hex-label {
              text-align: center;
              padding-top: 6.5px;
              color: #fff;
              @include font1;
              @include plan-label;
              position: absolute;
              width: 28px;
              height: 32px;
              z-index: 300;
              transform: translateX(-50%);
              &:hover {
                @include plan-label-hover;
                & ~ .text-box {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
            .text-box {
              @include font2;
              visibility: hidden;
              opacity: 0;
              transition: opacity 1s;
              line-height: 28px;
              position: absolute;
              height: 85px;
              top: -75px;
              left: -50%;
              background: $orange;
              border: 3px solid #fff;
              padding: 8px 10px 0 10px ;
              color: white;
              text-align: center;
              width: 140px;
              font-size: 24px;
              z-index: 200;
            }
            .sm {
              width: 200px;
              height: 55px;
              top: -100px;
              left: -70%;
            }
            .md {
              width: 200px;
              left: -75%;
              top: -70px;
              height: 80px;
            }
            .lg {
              width: 200px;
              height: 110px;
              left: -75%;
              top: -100px;
            }
            .xl {
              width: 300px;
              height: 110px;
              left: -105%;
              top: -100px;
            }
            .text-box.down {
              top: 21px;
            }
            .index {
              z-index: 100;
            }
          }
        }

        // Building labels
        .a-label {
          cursor: pointer;
          @include font2;
          position: absolute;
          top: 59%;
          left: 77%;
          text-align: center;
          font-size: 18px;
          z-index: 50;
          @include iphone {
            font-size: 10px;
            top: 115px;
            left: 275px;
          };
          @include iphone-landscape {
            font-size: 18px;
            top: 200px;
            left: 500px;
          }
          @include iphone6-plus-landscape {
            top: 223px;
            left: 550px;
          }
          @include iphone6-landscape {
            top: 202px;
            left: 490px;
          }
          @include iphone5-landscape {
            top: 175px;
            left: 433px;
            font-size: 13px;
          }
          @include iphone6-plus-portrait{
            top: 119px;
            left: 295px;
          }
          @include iphone6-portrait{
            top: 114px;
            left: 276px;
          }
          @include iphone5-portrait {
            left: 233px;
            top: 98px;
            font-size: 8px;
          };
          &:hover {
            & ~ .building-a {
              opacity: 1;
            }
          }
        }

        .b-label {
          cursor: pointer;
          @include font2;
          position: absolute;
          top: 16%;
          left: 63%;
          text-align: center;
          font-size: 18px;
          z-index: 50;
          @include ipad-portrait {
            top: 14%;
            left: 61%;
          };
          @include iphone {
            font-size: 10px;
            top: 25px;
            left: 225px;
          };
          @include iphone-landscape {
            font-size: 18px;
            top: 45px;
            left: 400px;
          }
          @include iphone6-plus-landscape {
            top: 52px;
            left: 442px;
          }
          @include iphone6-landscape{
            top: 46px;
            left: 402px;
          }
          @include iphone5-landscape{
            top: 42px;
            left: 347px;
            font-size: 13px;
          }
          @include iphone6-plus-portrait{
            top: 24px;
            left: 242px;
          }
          @include iphone6-portrait{
            left: 225px;
          }
          @include iphone5-portrait {
            font-size: 8px;
            left: 190px;
          };
          &:hover {
            & ~ .building-b {
              opacity: 1;
            }
          }
        }

        .c-label {
          cursor: pointer;
          @include font2;
          position: absolute;
          top: 42%;
          left: 17%;
          text-align: center;
          font-size: 18px;
          z-index: 50;
          @include ipad-landscape{
            top: 30%;
          }
          @include ipad-portrait {
            top: 28%;
            left: 15%;
          };
          @include iphone {
            top: 55px;
            left: 50px;
            font-size: 10px;
          };
          @include iphone-landscape {
            font-size: 18px;
            top: 100px;
            left: 90px;
          }
          @include iphone6-plus-landscape {
            left: 110px;
          }
          @include iphone6-landscape {
            left: 96px;
          }
          @include iphone5-landscape{
            left: 89px;
            top: 86px;
            font-size: 13px;
          }
          @include iphone6-plus-portrait{
            left: 50px;
            top: 58px;
          }
          @include iphone5-portrait {
            font-size: 8px;
            left: 45px;
          };
          &:hover {
            & ~ .building-c {
              opacity: 1;
            }
          }
        }

        // Building hover stages
        .building-a {
          cursor: pointer;
          background: red;
          width: 33.5%;
          height: 38.18%;
          position: absolute;
          top: 47.4%;
          left: 66.15%;
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjM4MXB4IiBoZWlnaHQ9IjIyM3B4IiB2aWV3Qm94PSIwIDAgMzgxIDIyMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy42LjEgKDI2MzEzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5ob3Zlci1idWlsZGluZy1hPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03NTkuMDAwMDAwLCAtMTQ3OS4wMDAwMDApIiBmaWxsPSIjRjRDODcyIj4KICAgICAgICAgICAgPGcgaWQ9InBsYW4iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzMuMDAwMDAwLCA5MzAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0ic2l0ZXBsYW4tMDQtMTEtMTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0MS4wMDAwMDAsIDI1Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNzg2LjY0MDYyNSwyOTIuOTI4NzExIEwxMTMxLjIyMTY4LDM1Ni40NjE5MTQgTDExMzEuNDA3MjMsMzY0LjAyMzQzOCBMMTEwNC4wNTM3MSw1MTQuMjI3NTM5IEw5MDUuMzI1MTk1LDQ3OS4yNTM5MDYgTDkwMS42MDkzNzUsNTAzLjc3MjQ2MSBMNzUxLjY4NDU3LDQ3NS41NTY2NDEgTDc4Ni42NDA2MjUsMjkyLjkyODcxMSBaIiBpZD0iaG92ZXItYnVpbGRpbmctYSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=);
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .5s;
          &:hover {
            opacity: 1;
          }
        }

        .building-b {
          cursor: pointer;
          background: red;
          width: 31.1%;
          height: 32.5%;
          position: absolute;
          top: 9.3%;
          left: 53.2%;
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjM1NXB4IiBoZWlnaHQ9IjIwM3B4IiB2aWV3Qm94PSIwIDAgMzU1IDIwMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy42LjEgKDI2MzEzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5ob3Zlci1idWlsZGluZy1iPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MTAuMDAwMDAwLCAtMTI0MC4wMDAwMDApIiBmaWxsPSIjRjRDODcyIj4KICAgICAgICAgICAgPGcgaWQ9InBsYW4iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzMuMDAwMDAwLCA5MzAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0ic2l0ZXBsYW4tMDQtMTEtMTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0MS4wMDAwMDAsIDI1Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNjAzLjI0NjA5NCw1My43OTAwMzkxIEw5NTYuNjE4MTY0LDU3LjgzNzg5MDYgTDk1NC4xNTIzNDQsMjIwLjA1MjczNCBMNzAzLjIzNDM3NSwyMTcuMTMyODEyIEw3MDMuNzk5ODA1LDI1NS44MzEwNTUgTDYwMi40MzE2NDEsMjM2LjQ4NjMyOCBMNjAzLjI0NjA5NCw1My43OTAwMzkxIFoiIGlkPSJob3Zlci1idWlsZGluZy1iIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .5s;
          &:hover {
            opacity: 1;
          }
        }

        .building-c {
          cursor: pointer;
          width: 37.3%;
          height: 59.9%;
          position: absolute;
          top: 8.8%;
          left: 6.3%;
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjQyMnB4IiBoZWlnaHQ9IjM3MHB4IiB2aWV3Qm94PSIwIDAgNDIyIDM3MCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy43LjIgKDI4Mjc2KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5ob3Zlci1idWlsZGluZy1jPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjE0NDAtLS1kZXNpZ24tMS0tLWludGVyaW9yLWhlcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03Mi4wMDAwMDAsIC0xMjQwLjAwMDAwMCkiIGZpbGw9IiNGNEM4NzIiPgogICAgICAgICAgICA8ZyBpZD0ic2l0ZS1wbGFuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc2LjAwMDAwMCwgOTMwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InNpdGVwbGFuLTA0LTExLTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODEuMDAwMDAwLCAyNjAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9ImhvdmVyLWJ1aWxkaW5nLWMiIHBvaW50cz0iODYuMDM5MDYyNSA1MC40MzM1OTM4IDQ4OS4wMTk1MzEgNTIuOTc4NTE1NiA0ODcuMjUzOTA2IDIxNC42Mzc2OTUgNDIwLjUxMzY3MiAyMTQuMjAzMTI1IDQxOC4wMzkwNjIgNDE5LjA2NDQ1MyAyMTYuNDAwMzkxIDQxOC45MzU1NDcgMjE2LjQwMDM5MSAzOTMuNTEzMTMzIDY3LjM1NjQ0NTMgMzkyLjk0NDMzNiA2OC41MjgzMjAzIDIzMS4xMjEwOTQgODUuMDM2MTMyOCAyMzEuMTI4OTA2Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==);
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .5s;
          &:hover {
            opacity: 1;
          }
        }

        padding-left: 0;
        img {
          // border: 5px solid $orange;
        }
        .plan-map-label {
          @include caption;
          position: absolute;
          top: 70px;
          left: 0;
          z-index: 50;
          width: 450px;
          height: 98px;
          color: #fff;
          padding: 5px 60px;
          @include ipad-portrait {
            @include mobile-caption;
            top: 300px;
            width: 353px;
            height: 92px;
            padding: 10px 0 0 15px;
            h4 {
              margin: 5px 0 0 0;
              font-size: 16px;
            }
          };
          @include ipad-landscape {
            @include mobile-caption;
            top: 300px;
            width: 353px;
            height: 92px;
            padding: 10px 0 0 15px;
            h4 {
              font-size: 16px;
              margin: 0;
              padding: 0;
            }
          };
          @include iphone {
            display: none;
          };
          h4 {
            text-transform: uppercase;
            line-height: 21px;
          }
        }

    }
    .plan-features {
      padding-bottom: 100px;
      h1 {
        padding-left: 20px;
      }
      ol {
        @include font2;
        padding-left: 21px;
      }
      @include iphone {
        padding-bottom: 0;
      };
      a {
        text-decoration: none;
        .entitlements-btn {
          @include hexa-plan;
          text-align: center;
          padding-top: 50px;
          color: #fff;
          line-height: 20px;
          width: 156px;
          height: 180px;
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          @include ipad-portrait {
            top: 20px;
            left: 600px;
            padding-top: 35px;
          };
          @include ipad-landscape {
            display: none;
          };
          @include iphone {
            top: 145px;
            left: 300px;
            width: 30%;
            padding-top: 30px;
            display: none;
          };
          @include iphone-landscape {
            left: 500px;
            top: 80px;
            width: 200px;
            height: auto;
            padding-top: 70px;
          };
          h4 {
            text-transform: uppercase;
            line-height: 19px;
            font-size: 16px;
            @include iphone {
              font-size: 10px;
            };
            @include iphone-landscape {
              font-size: 16px;
            };
          }
          &:hover {
            @include desktop {
              @include hexa-plan-hover;
            };
          }
        }
      }
    }
  }

}

.lease-override {
  background: #808080;
}
