.red {
  background: red;
}

.blue {
  background: blue;
}

// Global mobile styles
@include iphone {
  .icon-hexagon {
    display: none;
  }
};


// Global desktop styles
body {
  font-smoothing: antialiased;
   -webkit-font-smoothing: antialiased; //styles to make type look cleaner on chrome
}


section {
  overflow: hidden;
}


.top-padding {
  // padding-top: ($nav-height);
  // margin-top: 120px;
}

// Global font styles
h1, h2, h3, h4 {
  @include font1;
}

h1 {
  font-size: 24px;
  letter-spacing: 2px;
}

h2 {}

h3 {
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 2px;
}

h4 {
  letter-spacing: 2px;
  font-size: 18px;
}

p, li {
  @include font2;
  font-size: 16px;
  @include iphone {
    font-size: 14px
  };
}

a {
  outline: 0 !important;
}

.height-responsive {
  height: 47px;
  position: relative;
  top: -14px;
}

// Icon Hexagon
.icon-hexagon {
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjM0cHgiIGhlaWdodD0iNDVweCIgdmlld0JveD0iMCAwIDM0IDQ1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjcuMiAoMjgyNzYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPmljb24taGV4YWdvbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxNDQwLS0tZGVzaWduLTEtLS1pbnRlcmlvci1oZXJvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzguMDAwMDAwLCAtOTYwLjAwMDAwMCkiIGZpbGw9IiNERTY2MjUiPgogICAgICAgICAgICA8ZyBpZD0ic2l0ZS1wbGFuIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc2LjAwMDAwMCwgOTMwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTIzNi4wNjk2NTUsMzAgTDIyNC4yOTI5NjMsMzYuNzk4ODE1MyBMMjI0LjI5Mjk2Myw0OC42NjA2MTIzIEwyMTQsNTQuNjAzNTU0MSBMMjE0LDY4LjIwNDM5NjIgTDIyNS43NzY2OTEsNzUgTDIzNy41NTMzODMsNjguMjA0Mzk2MiBMMjM3LjU1MzM4Myw1Ni4zMzkzODc3IEwyNDcuODQ2MzQ2LDUwLjM5OTY1NzQgTDI0Ny44NDYzNDYsMzYuNzk4ODE1MyBMMjM2LjA2OTY1NSwzMCBaIE0yMjcuMjU4ODE0LDQ4LjY1NzQwMDggTDIyNy4yNTg4MTQsMzguNTEyMTY4MSBMMjM2LjA2OTY1NSwzMy40MjM0OTQxIEwyNDQuODgwNDk1LDM4LjUxMjE2ODEgTDI0NC44ODA0OTUsNDguNjg2MzA0NiBMMjM2LjA5Mzc0MSw1My43NTg5MjA5IEwyMjcuMjU4ODE0LDQ4LjY1NzQwMDggWiBNMjE2Ljk2NTg1MSw2Ni40ODk0Mzc2IEwyMTYuOTY1ODUxLDU2LjMxMzY5NTQgTDIyNS43NTI2MDUsNTEuMjQxMDc5MSBMMjM0LjU4OTEzOCw1Ni4zNDI1OTkyIEwyMzQuNTg5MTM4LDY2LjQ4OTQzNzYgTDIyNS43NzY2OTEsNzEuNTc2NTA1OSBMMjE2Ljk2NTg1MSw2Ni40ODk0Mzc2IFoiIGlkPSJpY29uLWhleGFnb24iPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
  top: 18px;
  left: 35px;
  width: 34px;
  height: 45px;
  z-index: 100;
}



// XL Modal fits 90% of the screen
.modal-md {
  width: 65%;
  @include iphone {
    width: auto;
  };
}

.modal-xl {
  width: 90%;
  max-width: 1215px;
}

// Modal
.modal {
  h2 {
    margin: 0;
    font-size: 24px;
    line-height: 34px;
    height: 34px;
    display: inline-block;
    letter-spacing: 2px;
  }
  margin-top: 15vh;
  .modal-dialog {
    margin: 42px auto;
    .modal-content {
      border-radius: 0;
      .modal-header {
        margin: 35px;
        border: none;
        padding: 0;
        .close {
          width: 65px;
          height: 75px;
          background-image: url('../images/button-modal-close.svg');
          position: absolute;
          top: -35px;
          left: calc(100% - 33px);
          opacity: 1;
        }
      }
    }
  }
}





.no-padding {
  padding: 0;
}

.footer {
  height: $nav-height;
  background: $orange;
  padding: 18px 0 0 80px;
  color: #fff;
}

.back-scroll {
  position: absolute;
  right: 80px;
  top: -7px;
  transform: translateX(-50%);
  width: 36px;
  height: 40px;
  color: #fff;
  transition: color .6s;
  &:hover {
    color: $light-gray;
  }
  @include iphone5-portrait {
    right: 10px;
  };
}
