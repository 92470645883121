/**
 * @license
 * MyFonts Webfont Build ID 3213298, 2016-05-03T13:42:07-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FuturaBT-Book by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/futura/book/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 *
 * Webfont: FreightSansProBook-Regular by GarageFonts
 * URL: http://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book/
 * Copyright: Copyright &#x00A9; 2004-2010, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3213298
 * Licensed pageviews: 10,000
 *
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3107f2");


@font-face {
  font-family: 'FuturaBT-Book';
  src: url('../fonts/3107F2_0_0.eot');
  src: url('../fonts/3107F2_0_0.eot?#iefix') format('embedded-opentype')
      ,url('../fonts/3107F2_0_0.woff2') format('woff2')
      ,url('../fonts/3107F2_0_0.woff') format('woff')
      ,url('../fonts/3107F2_0_0.ttf') format('truetype');
}


@font-face {
  font-family: 'FreightSansProBook-Regular';
  src: url('../fonts/3107F2_1_0.eot');
  src: url('../fonts/3107F2_1_0.eot?#iefix') format('embedded-opentype')
      ,url('../fonts/3107F2_1_0.woff2') format('woff2')
      ,url('../fonts/3107F2_1_0.woff') format('woff')
      ,url('../fonts/3107F2_1_0.ttf') format('truetype');
}
