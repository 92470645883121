$speed: 200ms;
$easing: cubic-bezier(0.7, 0, 0, 0.7);


nav.navbar-inverse {
  @include font2;
  border: none;
  margin: 0;
  border-radius: 0;
  background: $black;
  padding-top: 5px;
  padding-left: 30px;
  height: $nav-height;
  @include mobile {
    height: $nav-height !important;
  };
  @include iphone {
    padding-left: 0;
  };

  .navbar-toggle {
    overflow: hidden;

    .icon-bar {
      transition: opacity, transform;
      transition-duration: $speed;
      transition-timing-function: $easing;
    }

    &:not(.collapsed) {
      .icon-bar {
        &:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }
        &:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
        }
      }
    }
  }
  .navbar-brand {
    @include iphone {
      position: absolute;
      left: 50%;
      transform: translateX(-60%);
    };
  }
  .navbar-collapse {
    max-height: 100%;
    background: #39393A;
    @include iphone {
      position: relative;
      top: 5px;
    };
    z-index: 1000;
    ul.navbar-nav {
      @include iphone {
        height: 100vh !important;
      };
      li {
        text-align: center;
        font-size: 18px;
        @include iphone {
          padding-top: calc(100% / 6 );
        };
        @include iphone-landscape {
          padding-top: 10px;
        };
        a {
          outline: 0;
          color: #fff;
          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
}

.top-padding {
  padding-top: $nav-height;
}


// Active scroll state
#navbar-main {
  .navbar-nav {
    li.active {
      a {
        color: $orange;
        background: transparent;
        box-shadow: none;
      }
    }
  }
}



@include mobile {
  nav.navbar {
    height: 30px;
  }

  .navbar-collapse {
    background: #e8e8e8;
  }

  ul.navbar-nav {
    margin-top: 0;
  }
};


.navbar-inverse {
  .navbar-nav {
    .select {
      a {
        color: $orange;
      }
    }
  }
}
