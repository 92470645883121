//variables
$pb: 15px;
$vh: 75vh;


section.team-container {
  height: calc( 100vh - #{$nav-height} );
  @include font1;
  @include mobile {
    height: 100%;
  };
  background-image: url('../images/background-team@2x.jpg');
  background-size: cover;
  h1 {
    color: #fff;
    @include desktop {
      margin-left: 10%;
    };
  }
  // Global font styles
  h2, h3, h4 {
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 5px;
  }
  h3:first-child {
    padding: 0;
  }
  h3.team-heading, h4.leasing-heading {
    margin-top: 12px;
  }
  .heading {
    padding-top: 20px;
  }
  .text-container {
    @include desktop {
      margin-left: 10%;
    };
    height: $vh;
    padding-top: $pb;
    background: rgba(0, 0, 0, .5);
    @include ipad-portrait {
      height: 570px;
    };
    @include iphone {
      height: 190px;
    };
    @include iphone5-portrait {
      height: 210px;
    };
    p {
      color: #fff;
    }
  }
  .map-container {
    padding: 0;
    height: $vh;
    overflow: hidden;
    @include ipad-portrait {
      height: 570px;
    };
    @include iphone {
      height: 371px;
      max-width: 375px;
    };
    @include iphone-landscape {
      max-width: 667px;
    };
    @include iphone5-portrait {
      height: 316px;
    };
    img {
      height: 100%;
      @include mobile {
        width: 100%;
        height: auto;
      };
      @include iphone6-landscape {
        width: 667px;
        height: 660px;
      };
    }
  }
  .contacts-container {
    height: $vh;
    width: 280px;
    @include ipad-portrait {
      width: 100%;
      height: 380px;
    };
    @include ipad-landscape {
      width: 270px !important;
    };
    @include iphone {
      width: 100%;
      height: 570px;
    };
    @include iphone-landscape {
      height: 400px;
    }
    @include iphone5-landscape {
      height: 640px;
    }
    padding-top: $pb;
    background: rgba(255, 255, 255, .9);
    img {
      margin: 0 0 10px 0;
      width: 60%;
      @include ipad-portrait {
        width: 200px;
      };
    }
    img.jll-logo {
      width: 80%;
      @include ipad-portrait {
        width: 120px;
        margin: 0 0 10px 0;
      };
    }
    .contact-wrapper {
      position: relative;
    }
    .move-p {
      position: absolute;
      // bottom: 95px;
      top: 0;
      right: 10px;
      @include ipad-landscape{
        position: relative;
        left: 3px;
      }
    }
    .former-caption {
      margin: 0;
      font-size: 9px;
      @include mobile {
        text-align: left;
      };
    }
    @include ipad-portrait {
      $top-align: 20px;
      .address-container {
        position: absolute;
        top: $top-align;
      }
      .team-container {
        width: 250px;
        position: absolute;
        top: $top-align;
        // right: 250px;
        left: 20px;
      }
      .leasing-container {
        h4 {
          margin-top: 0;
        }
        position: absolute;
        top: $top-align;
        // right: 40px;
        left: 300px;
        p:last-child {
          position: absolute;
          top: 0;
          left: 200px;
        }
      }
    };

    p {
      line-height: 20px;
      margin: 0 0 -10px 0;
    }

  }
}
