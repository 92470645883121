section.amenities-container {
  @include font1;
  @include ipad-portrait {
    background: $amen-map;
    height: 70vh;
  };
  @include iphone-landscape {
    height: auto;
  };
  .row {
    .col-md-12 {
      padding: 0;
      height: 100%;
      h1 {
        position: absolute;
        top: 0;
        left: 80px;
        @include iphone {
          left: 15px;
        };
      }
      .amenities-map {
        width: 100%;
        height: auto;
        @include iphone {
          display: none;
        };
      }
      .legend-container {
        position: absolute;
        bottom: 5%;
        left: 10vw;
        width: 270px;
        height: 315px;
        @include ipad-landscape {
          top: 0;
          right: 6px;
        }
        @include ipad-portrait {
          bottom: -200px;
          left: 3vw;
        };
        @include iphone {
          display: none;
        };
        .amenities-legend {
          background-image: url('../images/amenities-legend-hexagon.svg');
          width: 223px;
          height: 258px;
          text-align: center;
          padding-top: 60px;
          h4 {
            color: #fff;
            text-transform: uppercase;
          }
          p {
            font-size: 18px;
            line-height: 18px;
            cursor: pointer;
            margin-bottom: 6px;
            &:hover {
              color: #fff;
            }
          }
          .retail {
            color: $light-yellow;
          }
          .hotels {
            color: $green;
          }
          .eats {
            color: $yellow;
          }
          .arts {
            color: $purple;
          }
          .tenants {
            color: $orange;
          }
          .select {
            color: #fff;
          }
        }
        .amenities-modal {
          @include hexa-solid;
          cursor: pointer;
          background-size: cover;
          position: relative;
          padding-top: 5px;
          top: -60px;
          left: 172px;
          width: 95px;
          height: 110px;
          &:hover {
            @include hexa-solid-hover;
          }
          h4 {
            text-align: center;
            margin: 0;
            color: #fff;
            line-height: 20px;
            padding-top: 25px;
            font-size: 14px;
          }
          p {
            text-align: center;
            color: #fff;
            font-size: 10px;
          }
        }
      }

      .amenity-marker {
        cursor: pointer;
        border-radius: .77vw;
        width: .77vw;
        height: .77vw;
        position: absolute;
        color: transparent;
        overflow: hidden;
        text-align: center;
        padding-top: 2px;
        transition: width .5s, height .5s, border-radius .5s, color .5s;
        @include iphone {
          display: none;
        };
      }

      .sm {
        &:hover {
          width: 100px;
          height: 22px;
          border-radius: 0;
          color: #000;
          z-index: 100;
        }
      }

      .md {
        &:hover {
          width: 150px;
          height: 22px;
          border-radius: 0;
          color: #000;
          z-index: 100;
        }
      }

      .lg {
        &:hover {
          width: 200px;
          height: 22px;
          border-radius: 0;
          color: #000;
          z-index: 100;
        }
      }

      .sm-wht {
        &:hover {
          width: 100px;
          height: 22px;
          border-radius: 0;
          color: #fff;
          z-index: 100;
        }
      }

      .md-wht {
        &:hover {
          width: 150px;
          height: 22px;
          border-radius: 0;
          color: #fff;
          z-index: 100;
        }
      }

      .lg-wht {
        &:hover {
          width: 230px;
          height: 22px;
          border-radius: 0;
          color: #fff;
          z-index: 100;
        }
      }

      .xl-wht {
        &:hover {
          width: 300px;
          height: 22px;
          border-radius: 0;
          color: #fff;
          z-index: 100;
        }
      }



      // Amenities Eats
      .iron-press {
        background:$yellow;
        top: 32.54%;
        left: 16.58%;
      }

      .greenleaf {
        background:$yellow;
        top: 33.9%;
        left: 16.22%;
      }

      .taco-maria {
        background:$yellow;
        top: 35.4%;
        left: 16.22%;
      }

      .portola {
        background:$yellow;
        top: 36.9%;
        left: 16.22%;
      }

      .greenleaf {
        background:$yellow;
        top: 33.9%;
        left: 16.22%;
      }

      .oysters-bar {
        background:$yellow;
        top: 32.54%;
        left: 17.45%;
      }

      .pueblo {
        background:$yellow;
        top: 34.1%;
        left: 17.45%;
      }

      .arc {
        background:$yellow;
        top: 35.6%;
        left: 17.45%;
      }

      .temakira {
        background:$yellow;
        top: 37.0%;
        left: 17.45%;
      }

      .papillote {
        background:$yellow;
        top: 38.6%;
        left: 17.10%;
      }

      .capital-grille {
        background:$yellow;
        top: 48.33%;
        left: 68.47%;
      }

      .season-52 {
        background:$yellow;
        top: 49.2%;
        left: 69.17%;
      }

      .water-grill {
        background:$yellow;
        top: 45.96%;
        left: 73.3%;
      }

      .vaca {
        background:$yellow;
        top: 43.2%;
        left: 74.38%;
      }

      .mastros {
        background:$yellow;
        top: 49.8%;
        left: 74.38%;
      }

      .holstein {
        background:$yellow;
        top: 52.2%;
        left: 71.89%;
      }

      .casanova {
        background:$yellow;
        top: 72.34%;
        left: 88.15%;
      }

      .habana {
        background:$yellow;
        top: 81.26%;
        left: 72.28%;
      }

      // Amenities Retail
      .camp {
        background:$light-yellow;
        top: 79.0%;
        left: 70.93%;
      }

      .anti-mall {
        background:$light-yellow;
        top: 84.87%;
        left: 69.89%;
      }


      // Amenities Hotels
      .arts-hotel {
        background:$green;
        top: 41.1%;
        left: 81.57%;
      }

      .westin {
        background:$green;
        top: 45.96%;
        left: 74.25%;
      }

      .hilton {
        background:$green;
        top: 66.32%;
        left: 74.25%;
      }

      .hilton {
        background:$green;
        top: 66.32%;
        left: 74.25%;
      }

      // Amenities Arts
      .segerstrom {
        background:$purple;
        top: 38.89%;
        left: 77.02%;
      }

      .repertory {
        background:$purple;
        top: 41.62%;
        left: 77.53%;
      }

      .segerstrom-concert {
        background:$purple;
        top: 44.61%;
        left: 76.49%;
      }

      .observatory {
        background:$purple;
        top: 20.53%;
        left: 27.08%;
      }

      // Amenities Tenants
      .vans {
        background:$orange;
        top: 40.60%;
        left: 19.39%;
      }

      .mazda {
        background:$orange;
        top: 48.34%;
        left: 73.68%;
      }


    }
  }

  div.amenities-accordion {
    h4 {
      text-align: center;
      font-size: 24px;
      color: #fff;
    }

    display: none;
    margin-top: 60px;
    .panel {
      border-radius: 0;
      border: none;
      margin: 0;
    }
    .panel-heading {
      margin: 0;
      border: none;
      border-radius: 0;
    }
    a {
      text-decoration: none;
      &:visited, &:active, &:focus {
        text-decoration: none;
      }
    }
    .collapse {
      .panel-body {
        border-top: none;
      }
    }
    @include iphone {
      display: block;
    };
    .retail {
      background: $light-yellow;
    }
    .retail-accordion {
      background: #f9e8c3;
    }
    .hotels {
      background: $green;
    }
    .hotels-accordion {
      background: #33cdab;
    }
    .eats {
      background: $yellow;
    }
    .eats-accordion {
      background: #e4c88f;
    }
    .arts {
      background: $purple;
      color: #fff;
    }
    .arts-accordion {
      background: #48435d;
      color: #fff;
    }
    .tenants {
      background: $orange;
    }
    .tenants-accordion {
      background: #e08e62;
    }
    .living {
      background: #979797;
      color: #fff;
    }
    .living-accordion {
      background: #bdbdbd;
    }
    ul {
      list-style-type: none;
      padding-left: 10px;
    }
  }
}



div.amenities-toolbox {
  background: #4a4a4a;
  border: 6px solid #8c8c8c;
  padding: 35px 0;
  color: #fff;
  h1 {
    padding-left: 40px;
  }
  h2 {
    padding-left: 70px;
  }
  p {
    padding: 0 40px;
  }
}
