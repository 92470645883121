section.idea-container {
  position: relative;
  @include font1;
  background: #fff;
  .concept-caption {
    @include caption;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 450px;
    height: 98px;
    z-index: 200;
    color: #fff;
    padding: 15px 0 0 60px;
    @include iphone {
      @include mobile-caption;
      width: 98%;
      padding: 18px 0 0 15px;
    };
    @include iphone-landscape {
      bottom: 20px;
    };
    h2 {
      margin: 0;
      line-height: 22px;
      font-size: 16px;
      letter-spacing: 1px;
      @include iphone {
        font-size: 14px;
      };
    }
    p {
      @include font2;
      @include iphone {
        font-size: 12px;
      };
    }
  }
  .caption {
    display: none;
  }

  .caption-1 {
    display: block;
  }

  .active-caption {
    display: block;
  }

  .idea-concept {
    background-size: cover;
    height: calc(100vh - #{ $nav-height });
    padding-left: 80px;
    @include iphone {
      padding-left: 15px;
    };
    img {
      position: absolute;
      top: 22px;
      left: -28px;
    }
    p {
      @include font2;
      font-size: 16px;
      line-height: 22px;
      @include iphone {
        font-size: 14px;
      };
    }
    .caption-footer {
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 450px;
      height: 98px;
      color: #fff;
      padding: 15px 0 0 60px;
      h2 {
        margin: 0;
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 1px;
      }
      p {
        @include font2;
      }
    }
  }
  // Carousel bg images
  .idea-concept.first {
    background-image: url('../images/background-rendering-front-day.jpg');
    background-position: center;
  }
  .idea-concept.second {
    background-image: url('../images/background-rendering-glass-barn.jpg');
    background-position: center;
  }
  .idea-concept.third {
    background-image: url('../images/background-rendering-cafe-exterior.jpg');
    background-position: center;
  }
  .idea-concept.fourth {
    background-image: url('../images/background-rendering-cafe-interior.jpg');
    background-position: right;
  }
  .idea-concept.fifth {
    background-image: url('../images/background-rendering-front-dusk.jpg');
    background-position: center;
  }
  .right-arrow-label {
    position: absolute;
    top: 50%;
    transform: translateY(-49.9%);
    right: 95px;
    text-align: right;
    letter-spacing: 2px;
    @include iphone {
      right: 40px;
      font-size: 10px;
    };
  }
  .left-arrow-label {
    position: absolute;
    top: 50%;
    transform: translateY(-49.9%);
    left: 95px;
    letter-spacing: 2px;
    @include iphone {
      left: 40px;
      font-size: 10px;
    };
  }
}

// Carousel arrows
.arrow-right {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-left: 35px solid white;
  transition: border-left .7s;
  &:hover {
    border-left: 35px solid $orange;
    @include iphone {
      border-left: 20px solid $orange !important;
    };
  }
  @include iphone {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 20px solid white;
    right: 10px;
  };
}

.arrow-left {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-right: 35px solid white;
  transition: border-right .7s;
  &:hover {
    border-right: 35px solid $orange;
    @include iphone {
      border-right: 20px solid $orange !important;
    };
  }
  @include iphone {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 20px solid white;
    left: 10px;
  };
}


// Carousel indicators
.carousel-indicators {
  width: 100px;
  left: 120vw;
  li {
    background: #fff;
    &.active {
      background: $orange;
    }
  }
  @include mobile {
    left: 110vw;
  };
  @include iphone6-plus-portrait {
    left: 420px;
  };
  @include iphone6-portrait {
    left: 380px;
  };
  @include iphone5-portrait {
    left: 310px;
  };
}

a.carousel-control {
  opacity: 1;
  transition: color .7s;
  &:hover {
    color: $orange;
  }
}

// Carousel arrow btns
.carousel-control.right {
  background: transparent;
  &:hover {
    .arrow-right {
      border-left: 35px solid $orange;
    }
  }
}
.carousel-control.left {
  background: transparent;
  &:hover {
    .arrow-left {
      border-right: 35px solid $orange;
    }
  }
}
