// Colors
$orange: #DE6625;
$light-orange: #E5822C;
$blue: #01707C;
$green: #2AAE91;
$yellow: #dbae54;
$light-yellow: #FFDD93;
$black: #39393A;
$gray: #39393A;
$light-gray: #4A4A4A;
$light-gray-2: #D8D8D8;
$purple: #25232C;

// Navbar
$nav-height: 60px;



//Pages

// amenities-map color
$amen-map: #FCF5ED;
