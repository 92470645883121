
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
 $responsive-unitless: $responsive / ($responsive - $responsive + 1);
 $dimension: if(unit($responsive) == 'vh', 'height', 'width');
 $min-breakpoint: $min / $responsive-unitless * 100;
 @media (max-#{$dimension}: #{$min-breakpoint}) {
   font-size: $min;
 }
 @if $max {
   $max-breakpoint: $max / $responsive-unitless * 100;
   @media (min-#{$dimension}: #{$max-breakpoint}) {
     font-size: $max;
   }
 }
 @if $fallback {
   font-size: $fallback;
 }
 font-size: $responsive;
}


// Webfonts

// Futura
@mixin font1 {
  font-family: FuturaBT-Book;
  font-weight: normal;
  font-style: normal;
}

// FreightSansProBook
@mixin font2 {
	font-family: FreightSansProBook-Regular;
	font-weight: normal;
	font-style: normal;
}


@mixin non-mobile {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin mobile {
  @media only screen
  and (min-width: 0px)
  and (max-width: 768px)
  and (-webkit-min-device-pixel-ratio: 1)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

// Tablets
@mixin ipad {
  @media only screen
  and (min-device-width: 667px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
@mixin ipad-landscape {
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1)
  and (-webkit-min-device-pixel-ratio: 2){
    @content;
  }
}
@mixin ipad-portrait {
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

// iPhones
@mixin iphone {
  @media only screen
  and (min-device-width: 0px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
@mixin iphone-landscape {
  @media only screen
  and (min-width: 568px)
  and (max-width: 736px)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
@mixin iphone-portrait {
  @media only screen
  and (min-width: 0px)
  and (max-width: 414px)
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
@mixin iphone6-plus-landscape {
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape)  {
    @content;
  }
}
@mixin iphone6-plus-portrait {
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
    @content;
  }
}
@mixin iphone6-landscape {
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    @content;
  }
}
@mixin iphone6-portrait {
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    @content;
  }
}
@mixin iphone5-landscape {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    @content;
  }
}
@mixin iphone5-portrait {
  @media only screen
  and (device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    @content;
  }
}
@mixin iphone4-landscape {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    @content;
  }
}
@mixin iphone4-portrait {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    @content;
  }
}
